import React, { useRef } from "react";
import { 
  Flex, 
  Layout,  
  Image,
  Table,
  Timeline,
  Carousel,
  Button
} from "antd";
import './Home.css';
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { Item } = Timeline;

const contentStyle = {
  textAlign: 'center',
  minHeight: 120,
  lineHeight: '120px',
  color: '#fff',
  backgroundColor: '#0958d9',
};

const columns = [
  {
    title: '',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: 'Removable Retainer',
    dataIndex: 'con',
    key: 'con',
    align: 'center', 
  },
  {
    title: 'Classic Permanent Retainer',
    dataIndex: 'res',
    key: 'res',
    align: 'center', 
  },
  {
    title: 'iRetain',
    dataIndex: 'ire',
    key: 'ire',
    align: 'center'
  },
];

const dataSource = [
  {
    key: '1',
    name: (<span className="h1style">Invisibility</span>),
    con: (
      <div>
        <img src="/images/xcircle.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">May be visible</span>
      </div>
    ),
    res: (
      <div>
        <img src="/images/check.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Completely hidden</span>
      </div>
    ),
    ire: (
      <div>
        <img src="/images/check.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Completely hidden</span>
      </div>
    )
  },
  {
    key: '2',
    name: (<span className="h1style">Durability</span>),
    con: (
      <div>
        <img src="/images/xcircle.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Easy to fracture or lost</span>
      </div>
    ),
    res: (
      <div>
        <img src="/images/xcircle.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Food Influence to debonding</span>
      </div>
    ),
    ire: (
      <div>
        <img src="/images/check.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Special Biomaterial</span>
      </div>
    )
  },
  {
    key: '3',
    name: (<span className="h1style">Replacement</span>),
    con: (
      <div>
        <img src="/images/xcircle.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">High frequency</span>
      </div>
    ),
    res: (
      <div>
        <img src="/images/check.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Long term</span>
      </div>
    ),
    ire: (
      <div>
        <img src="/images/check.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Long term</span>
      </div>
    )
  },
  {
    key: '4',
    name: (<span className="h1style">Comfort</span>),
    con: (
      <div>
        <img src="/images/check.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Comfort</span>
      </div>
    ),
    res: (
      <div>
        <img src="/images/xcircle.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Foreign object sensation</span>
      </div>
    ),
    ire: (
      <div>
        <img src="/images/check.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Personalized Biodesign</span>
      </div>
    )
  },
  {
    key: '5',
    name: (<span className="h1style">Oral Hygiene</span>),
    con: (
      <div>
        <img src="/images/check.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Easy to clean </span>
      </div>
    ),
    res: (
      <div>
        <img src="/images/xcircle.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Prone to food impaction</span>
      </div>
    ),
    ire: (
      <div>
        <img src="/images/check.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Biologically optimized bracket surface</span>
      </div>
    )
  },
  {
    key: '6',
    name: (<span className="h1style">Patient Adherence</span>),
    con: (
      <div>
        <img src="/images/xcircle.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">Low</span>
      </div>
    ),
    res: (
      <div>
        <img src="/images/check.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">High</span>
      </div>
    ),
    ire: (
      <div>
        <img src="/images/check.png" alt="Check" style={{ marginBottom: 5 }} />
        <br />
        <span className="htstyle">High</span>
      </div>
    )
  }
];

const steps = [
  {
    id: 1,
    image: "/images/stepp1.png", 
    icon: "/images/step1.png",
    description: (
      <Flex align="flex-start" gap={16}>
        <div style={{ lineHeight: 0 }}>
          <Image
            src="/images/icon1.png"
            alt="Icon"
            preview={false}
            width={window.innerWidth < 768 ? 36 : 77}
          />
        </div>

        <div style={{ textAlign: "right", flex: 1 }}>
          <div className="ststyle" style={{ marginBottom: 8 }}>Digitization and digital design</div>

          <div className="s1style" style={{ position: "relative", paddingLeft: 20 }}>
           • STL files of the dental arches are loaded into a graphics program.
          </div>

          <div className="s1style" style={{ position: "relative", paddingLeft: 20 }}>
           • Geometric algorithms support semi-automatic pad and wire design.
          </div>
        </div>
      </Flex>
    )
  },
  {
    id: 2,
    image: "/images/stepp2.png", 
    icon: "/images/step2.png",
    description: (
      <Flex align="flex-start" gap={16} style={{height: 250}}>
        <div style={{ lineHeight: 0 }}>
          <Image
            src="/images/icon2.png"
            alt="Icon"
            preview={false}
            width={window.innerWidth < 768 ? 36 : 77}
          />
        </div>

        <div style={{ textAlign: "right", flex: 1 }}>
          <div className="ststyle" style={{ marginBottom: 8 }}>Optimizing fit</div>

          <div className="s1style" style={{ position: "relative", paddingLeft: 20 }}>
           • The pads and arch wires are placed in strategically designed positions to avoid occlusal interference and soft tissue abrasion.
          </div>

          <div className="s1style" style={{ position: "relative", paddingLeft: 20 }}>
           • The orthodontist can digitally adjust the position and shape of the pads and wire.
          </div>
        </div>
      </Flex>
    )
  },
  {
    id: 3,
    image: "/images/stepp3.png", 
    icon: "/images/step3.png",
    description: (
      <Flex align="flex-start" gap={16} style={{height: 250}}>
        <div style={{ lineHeight: 0 }}>
          <Image
            src="/images/icon3.png"
            alt="Icon"
            preview={false}
            width={window.innerWidth < 768 ? 36 : 77}
          />
        </div>
        <div style={{ textAlign: "right", flex: 1 }}>
          <div className="ststyle" style={{ marginBottom: 8 }}>Additive manufacturing of metal pads</div>

          <div className="s1style" style={{ position: "relative", paddingLeft: 20 }}>
           • 3D printing in Ni-Cr alloy for optimum strength.
          </div>

          <div className="s1style" style={{ position: "relative", paddingLeft: 20 }}>
           • Assembled with nickel-titanium wire.
          </div>
        </div>
      </Flex>
    )
  },
  {
    id: 4,
    image: "/images/stepp4.png", 
    icon: "/images/step4.png",
    d1: "/images/icon4.png",
    description: (
      <Flex align="flex-start" gap={16} style={{height: 250}}>
        <div style={{ lineHeight: 0 }}>
          <Image
            src="/images/icon4.png"
            alt="Icon"
            preview={false}
            width={window.innerWidth < 768 ? 36 : 77}
          />
        </div>
        <div style={{ textAlign: "right", flex: 1 }}>
          <div className="ststyle" style={{ marginBottom: 8 }}>Preparation and gluing</div>

          <div className="s1style" style={{ position: "relative", paddingLeft: 20 }}>
           • Use of a transfer tray, similar to lingual padss, to ensure precise positioning.
          </div>

          <div className="s1style" style={{ position: "relative", paddingLeft: 20 }}>
           • Assembled with nickel-titanium wire.
          </div>
        </div>
      </Flex>
    )
  },
  {
    id: 5,
    image: "/images/stepp5.png", 
    icon: "/images/step5.png",
    d1: "/images/icon5.png",
    description: (
      <Flex align="flex-start" gap={16} style={{height: 250}}>
        <div style={{ lineHeight: 0 }}>
          <Image
            src="/images/icon5.png"
            alt="Icon"
            preview={false}
            width={window.innerWidth < 768 ? 36 : 77}
          />
        </div>
        <div style={{ textAlign: "right", flex: 1 }}>
          <div className="ststyle" style={{ marginBottom: 8 }}>Finalization and control</div>

          <div className="s1style" style={{ position: "relative", paddingLeft: 20 }}>
           • Checking adhesion and occlusion.
          </div>

          <div className="s1style" style={{ position: "relative", paddingLeft: 20 }}>
           • Patient follow-up to ensure optimal adaptation.
          </div>
        </div>
      </Flex>
    )
  },
];

const images = [
  "/images/c01.png",
  "/images/c02.png"
];

const Home = () => {

    const carouselRef = useRef(null);
  
    const handlePrev = () => {
      carouselRef.current.prev();
    };
  
    const handleNext = () => {
      carouselRef.current.next();
    };

  return (
      <Content style={contentStyle}>
        <Flex className="responsive-flex" vertical
          style={{
            padding: 80,
            background: ' #6CA8E6',
          }}
        >

          <Flex style={{ width: '100%' }}>
            <Flex style={{ flex: 1 }}>
              <Flex vertical style={{ alignItems: 'flex-start' }}>
                <div className="responsive-text"
                  style={{
                    fontFamily: 'Inter-Semi Bold, Inter, sans-serif',
                    fontWeight: 600, 
                    fontSize: 60,
                    color: '#060042',
                    lineHeight: '72px', 
                  }}
                >
                  iRetain
                </div>
                <div className="responsive-text"
                  style={{
                    fontFamily: 'Inter-Semi Bold, Inter, sans-serif',
                    fontWeight: 600,
                    fontSize: 60,
                    color: '#ffffff',
                    lineHeight: '72px', 
                    textAlign: 'left',
                  }}
                >
                  The optimal orthodontic retainer
                </div>

                <Flex align="center" style={{ marginTop: 26 }}>
                  <Image
                    src="/images/i1.png"
                    preview={false}
                    width={25}
                    style={{ display: 'block', minWidth: 26, marginRight: 18 }}
                  />
                  <span
                    style={{
                      fontFamily: 'Inter, sans-serif',
                      fontWeight: 500,
                      fontSize: 18,
                      color: '#FFFFFF',
                      marginLeft: 18,
                      lineHeight: '27px',
                      textAlign: 'left',
                    }}
                  >
                    The world's first retainer featuring:<br></br>
                    - a perfect fit, fully personalized, 0.4mm<span style={{ fontSize: '24px', verticalAlign: '-1px' }}>⌀</span> super-elastic Nickel-Titanium wire<br></br>
                    - unbreakable, ultra thin personalized pads
                  </span>
                </Flex>

                <Flex align="center" style={{ marginTop: 26 }}>
                  <Image
                    src="/images/i1.png"
                    preview={false}
                    width={25}
                    style={{ display: 'block', minWidth: 26, marginRight: 18 }}
                  />
                  <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', textAlign: 'left', marginLeft: 18 }}>
                    <span
                      style={{
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: 500,
                        fontSize: 18,
                        color: '#FFFFFF',
                        lineHeight: '27px', 
                      }}
                    >
                      Optimized and engineered with
                    </span>
                    <span
                      style={{
                        fontFamily: 'Inter-Bold Italic, Inter, sans-serif',
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                        fontSize: 18,
                        color: '#060042',
                        lineHeight: '27px', 
                      }}
                    >
                      &nbsp;advanced CAD
                    </span>
                    <span
                      style={{
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: 500,
                        fontSize: 18,
                        color: '#FFFFFF',
                        lineHeight: '27px', 
                      }}
                    >
                      &nbsp;and
                    </span>
                    <span
                      style={{
                        fontFamily: 'Inter-Bold Italic, Inter, sans-serif',
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                        fontSize: 18,
                        color: '#060042',
                        lineHeight: '27px', 
                      }}
                    >
                      &nbsp;AI Technology
                    </span>
                    <span
                      style={{
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: 500,
                        fontSize: 18,
                        color: '#FFFFFF',
                        lineHeight: '27px', 
                      }}
                    >
                      &nbsp;for an unprecedented fit, comfort and durability.
                    </span>
                  </div>
                </Flex>

                <Flex align="center" style={{marginTop: 30}}>
                  <span
                    style={{
                      fontFamily: 'Inter-Semi Bold, Inter, sans-serif',
                      fontWeight: 600,
                      fontSize: 36,
                      color: '#060042',
                      lineHeight: '43.2px', 
                      textAlign: 'left',
                    }}
                  >
                    iRetain your smile !
                  </span>
                </Flex>
              </Flex>
            </Flex>

            <Flex style={{ flex: 1, justifyContent: 'center', alignItems: 'center'}}>
              <video className="responsive-video" 
                controls
                autoPlay
                muted
                loop 
                controlsList="nodownload" 
                onContextMenu={(e) => e.preventDefault()}
                style={{ maxWidth: '100%', height: 'auto', borderRadius: 8, paddingLeft:10, paddingRight:10}} 
              >
                <source src="/videos/iretain.mp4" type="video/mp4" /> 
              </video>
            </Flex>
          </Flex>


          <Flex vertical style={{marginTop: 30}}>
            <div 
              style={{
                fontFamily: 'Inter-Semi Bold, Inter, sans-serif',
                fontWeight: 600,
                fontSize: 24,
                lineHeight: '43.2px', 
                textAlign: 'left',
              }}
            >
              THE SOLUTION TO RETAINER FAILURES <br></br>
            </div>
            <div 
              style={{
                fontFamily: 'Inter, sans-serif',
                fontWeight: 500,
                fontSize: 18,
                lineHeight: '27px', 
                textAlign: 'left',
              }}
            >
              IRetain is derived from a scientific analysis of conventional retainers' failures. <br></br>
              With conventional retainers, 
              <span 
                      style={{
                        fontFamily: 'Inter-Bold Italic, Inter, sans-serif',
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                        fontSize: 18,
                        color: '#060042',
                        lineHeight: '27px', 
                      }}
              >
              &nbsp; most of the failures are due to the fracture of the composite pads</span>
              &nbsp;in which the wire is embedded, 
              <span 
                      style={{
                        fontFamily: 'Inter-Bold Italic, Inter, sans-serif',
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                        fontSize: 18,
                        color: '#060042',
                        lineHeight: '27px', 
                      }}
              >
              &nbsp;
              with no influence of the type of the wire on the failure rate.</span><br></br>
              That is why iretain replaces the composite pad with an unbreakable personalized pad. <br></br><br></br>
              Furthermore, stainless steel wire retainer have the potential to produce inadvertent tooth displacement if enough force is applied to permanently deform the wire.<br></br>
              That is why iretain use a perfect fit personalized nickel titanium alloy wire, whose superelasticity allows physiological movements of the teeth but is impossible to deform permanently and therefore has a shape memory property. 
            </div>
          </Flex>

        </Flex>

        <Flex
          style={{
            padding: '120px 0 0 0', 
            background: '#FFFFFF',
          }}
          vertical
        >
          <Flex
            justify="center"
            style={{
              fontSize: 36,
              fontWeight: 500,
              marginBottom: 60,
              color: '#060042',
              lineHeight: 0,
            }}
          >
            Advantages of iRetain
          </Flex>

          <Flex justify="center" style={{ width: '100%' }}>
            <div
              className="table-container"
              style={{
                maxWidth: 1220, 
                width: '100%',  
                margin: '0 auto', 
              }}
            >
              <Table
                id="table_a"
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                rowClassName={(record, index) => (index % 2 === 0 ? 'h-row-even' : 'h-row-odd')}
                components={{
                  header: {
                    cell: (props) => (
                      <th
                        {...props}
                        style={{
                          background: '#6CA8E6',
                          color: '#FFFFFF',
                          fontWeight: 'bold',
                        }}
                      />
                    ),
                  },
                }}
              />
              <div className="last-column-border" />
            </div>
          </Flex>
        </Flex>

        <Flex
          style={{
            padding: '120px 0 0 0', 
            background: '#FFFFFF',
          }}
          vertical
        >

            <Flex
              justify="center" 
              style={{
                fontSize: 36,
                fontWeight: 500,
                marginBottom: 20,
                lineHeight: 1.5,
                color: '#060042'
              }}
            >
              The Step-by-Step Process
            </Flex>

            <Flex justify="center" style={{ width: "100%", marginTop: 40 }}>
              <Flex style={{ width: "80%", maxWidth: 1220, gap: 24 }}>
                {/*<Flex vertical style={{ width: "45%", gap: 24, alignItems: "flex-end" }}>
                  {steps.map((step) => (
                    <div key={step.id} style={{ height: 220,  alignItems: "left" }}>
                      <Image
                        src={step.image}
                        alt={`Step ${step.id}`}
                        preview={false}
                        style={{ width: 380, height: 170 }}
                      />
                    </div>
                  ))}
                </Flex>*/}

                <Flex vertical style={{ width: "10%", justifyContent: "center", alignItems: "center" }}>
                  <Timeline mode="left">
                    {steps.map((step) => (
                      <Item
                        key={step.id}
                        dot={
                          <Image
                            src={step.icon}
                            alt={`Step ${step.id}`}
                            preview={false}
                            width={window.innerWidth < 768 ? 24 : 36}
                          />
                        }
                        style={{ height: window.innerWidth < 768 ? 345 : 245 }}
                      >
                      </Item>
                    ))}
                  </Timeline>
                </Flex>

                <Flex vertical style={{ width: "90%", gap: 24, justifyContent: "center", marginTop: '-55px' }}>
                  {steps.map((step) => (
                    <div key={step.id} style={{ height: window.innerWidth < 768 ? 320 : 220,  alignItems: "center"}}>
                      <Flex align="center" gap={8}>
       
                        <div>{step.description}</div>
                      </Flex>
                    </div>
                  ))}
                </Flex>
              </Flex>
            </Flex>

        </Flex>    


        <Flex
          style={{
            padding: '20px 80px 100px 80px',
            background: '#FFFFFF',
          }}
          vertical 
        >  
            <Flex
              justify="center" 
              style={{
                fontSize: 36,
                fontWeight: 500,
                color: '#060042'
              }}
            >
              Clinical cases
            </Flex>

            <Flex
              justify="center" 
              style={{
                fontSize: 18,
                fontWeight: 500,
                marginTop: -60,
                color: 'rgba(0,0,0,0.6)'
              }}
            >
              A glimpse of our portfolio
            </Flex>

            <div style={{ position: "relative", width: "100%", maxWidth: 850, margin: "0 auto" }}>
              <Button
                type="text"
                icon={<LeftOutlined />}
                onClick={handlePrev}
                style={{
                  position: "absolute",
                  left: -50,
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 1,
                }}
              />
              <Button
                type="text"
                icon={<RightOutlined />}
                onClick={handleNext}
                style={{
                  position: "absolute",
                  right: -50,
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 1,
                }}
              />

              <Carousel
                ref={carouselRef}
                dots={false}
                slidesToShow={window.innerWidth <= 768 ? 1 : 2}
                slidesToScroll={1}
                autoplay
                autoplaySpeed={2000}
                infinite
                responsive={[
                  {
                    breakpoint: 768,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                    },
                  },
                ]}
              >
                {images.map((image, index) => (
                  <div className="responsive-carousel"
                    key={index}
                    style={{
                      width: "calc(50% - 10px)",
                      marginRight: "20px", 
                    }}
                  >
                    <Image
                      src={image}
                      alt={`Image ${index + 1}`}
                      style={{ width: "100%", height: "auto", borderRadius: 8 }}
                    />
                  </div>
                ))}
              </Carousel>

            </div>

        </Flex>    


      </Content>
  );
};

export default Home;