import React/*, { useState } */from 'react';
import {
  Button,
  Form,
  Input,
  Select,
  Layout,
  Flex,
  Image,
  message 
} from 'antd';
import { COUNTRIES } from '../constants';
import { useNavigate } from "react-router-dom";
import 'antd/dist/reset.css';
import './Register.css';


const { Content } = Layout;


message.config({
  top: 100, 
  duration: 2, 
});

const formItemLayout = {
  labelCol: { span: 24 }, 
  wrapperCol: { span: 24 }, 
};

const tailFormItemLayout = {
  wrapperCol: { offset: 0, span: 24 },
};

const gradientStyle = {
  background: 'linear-gradient(to bottom, #64A7E6, #060042)', 
  display: 'flex',
  color: '#fff', 
  fontSize: '24px',
  width: '100%'
};

const labelStyle = {
  fontWeight: '400',
  fontSize: 16,
  color: '#FFFFFF',
  marginBottom: 0
};

const inputStyle = {
  width: '100%', 
  marginTop: -16, 
  backgroundColor: 'rgba(255,255,255,0.05)',
  borderRadius: 5,
  border: '1px solid rgba(255,255,255,0.2)',
  color: '#FFFFFF'
}

const mStyle = {
  fontWeight: '500',
  fontSize: 16,
  color: '#FFFFFF',
}



/*const genderoptions = [
  {
    label: 'Male',
    value: '1',
  },
  {
    label: 'Female',
    value: '0',
  }
];*/

const Register = () => {
  const [form] = Form.useForm();

  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  /*const [captchaImage, setCaptchaImage] = useState(''); 

  const fetchCaptcha = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const captchaUrl = `${apiUrl}/captcha.php?${new Date().getTime()}`;
    setCaptchaImage(captchaUrl);
  };*/

  const onFinish = async (values) => {
    /*try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/verify_captcha.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          captcha: values.captcha, 
        }),
      });
      const result = await response.json();
      console.log(result);
      if (result.success) {
        message.success('Captcha verified successfully!');
        console.log("注册信息:", values);

      } else {
        message.error('Invalid captcha!');
      }
    } catch (error) {
      message.error('Failed to verify captcha');
    }*/
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/inscrit/new`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: values, 
        }),
      });
      const result = await response.json();

      if (result.code === 200) {
        messageApi.success('You have successfully registered. We will contact you as soon as possible!');
       
        window.location.href = '/'; 
        
      } else {
        messageApi.error('Contact Us: support@iretain.ai');
      }
    } catch (error) {
      messageApi.error('Contact Us: support@iretain.ai');
    }
   
  };

  const countryOptions = Object.entries(COUNTRIES).map(([code, name]) => ({
    value: code, 
    label: name, 
  }));

  
  return (
    <Content style={gradientStyle}>

        <Flex
          style={{
            width: '100%',
            background: 'rgba(255,255,255,0.04)',
            borderRadius:25,
            margin: window.innerWidth <= 768 ? '0px 16px' : '40px 80px',
            justifyContent: 'center'
          }}
        >
          {contextHolder}
          {/*<Flex style={{ flex: 1, justifyContent: 'center', alignItems: 'center',margin: '60px 45px' }}>
              <Image
                src="/images/r1.png"
                alt="Logo"
                preview={false}
                style={{}}
              />
          </Flex>*/}

          <Flex
            className="responsive-container"
            style={{
              width: '100%',
              margin: window.innerWidth <= 768 ? '0px 16px' : '40px 80px', 
              flex: 1, 
              flexDirection: 'column',
              alignItems: 'center', 
            }}
          >
            
            <div style={{ fontWeight: 'normal', fontSize: 30, color: '#FFFFFF', marginBottom: 5 }}>
              iRetain Doctor Register
            </div>
            <Form
              className="responsive-form"
              {...formItemLayout}
              form={form}
              name="register"
              onFinish={onFinish}
              style={{ maxWidth: 600, width: '100%' }}
              scrollToFirstError
              requiredMark={false}
            >
              {/* 邮箱输入框 */}
              <Form.Item
                name="email"
                label={<span style={labelStyle}>* E-mail</span>}
                rules={[
                  { type: 'email', message: 'The input is not valid E-mail!' },
                  { required: true, message: 'Please input your E-mail!' },
                ]}
              >
                <Input placeholder="Enter your E-mail" style={{ ...inputStyle, width: '100%' }} />
              </Form.Item>

              {/* 姓名输入框 */}
              <Flex className="responsive-flex" gap={20} style={{ flexDirection: window.innerWidth <= 768 ? 'column' : 'row' }}>
                <Form.Item
                  name="firstname"
                  label={<span style={labelStyle}>* Firstname</span>}
                  rules={[{ required: true, message: 'Please input your firstname!', whitespace: true }]}
                  style={{ flex: 1, width: '100%' }}
                >
                  <Input placeholder="Enter your firstname" style={{ ...inputStyle, width: '100%' }} />
                </Form.Item>

                <Form.Item
                  name="lastname"
                  label={<span style={labelStyle}>* Lastname</span>}
                  rules={[{ required: true, message: 'Please input your lastname!', whitespace: true }]}
                  style={{ flex: 1, width: '100%' }}
                >
                  <Input placeholder="Enter your lastname" style={{ ...inputStyle, width: '100%' }} />
                </Form.Item>
              </Flex>

              {/* 电话输入框 */}
              <Form.Item
                name="phone"
                label={<span style={labelStyle}>* Phone Number</span>}
                rules={[{ required: true, message: 'Please input your phone number!' }]}
              >
                <Input placeholder="Enter your phone number" style={{ ...inputStyle, width: '100%' }} />
              </Form.Item>

              {/* 国家选择框 */}
              <Form.Item
                name="country"
                label={<span style={labelStyle}>* Country</span>}
                rules={[{ required: true, message: 'Please input your country!' }]}
              >
                <Select
                  showSearch
                  placeholder="Select a country"
                  style={{ ...inputStyle, width: '100%' }}
                  optionFilterProp="label"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  options={countryOptions}
                />
              </Form.Item>

              {/* 城市和邮编输入框 */}
              <Flex className="responsive-flex" gap={20} style={{ flexDirection: window.innerWidth <= 768 ? 'column' : 'row' }}>
                <Form.Item
                  name="city"
                  label={<span style={labelStyle}>* City</span>}
                  rules={[{ required: true, message: 'Please input your city!' }]}
                  style={{ flex: 1, width: '100%' }}
                >
                  <Input placeholder="Enter your city" style={{ ...inputStyle, width: '100%' }} />
                </Form.Item>

                <Form.Item
                  name="zipcode"
                  label={<span style={labelStyle}>* Zipcode</span>}
                  rules={[{ required: true, message: 'Please input your zipcode!' }]}
                  style={{ flex: 1, width: '100%' }}
                >
                  <Input placeholder="Enter your zipcode" style={{ ...inputStyle, width: '100%' }} />
                </Form.Item>
              </Flex>

              {/* 地址输入框 */}
              <Form.Item
                name="address"
                label={<span style={labelStyle}>* Address</span>}
                rules={[{ required: true, message: 'Please input your Address!' }]}
              >
                <Input placeholder="Enter your address" style={{ ...inputStyle, width: '100%' }} />
              </Form.Item>

              {/* 注册和返回按钮 */}
              <Form.Item {...tailFormItemLayout}>
                <Flex className="responsive-flex" gap="middle" style={{ flexDirection: window.innerWidth <= 768 ? 'column' : 'row' }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="responsive-button"
                    style={{
                      width: window.innerWidth <= 768 ? '100%' : 216,
                      height: 43,
                      background: '#64A7E6',
                      borderRadius: 69,
                    }}
                  >
                    <span style={mStyle}>Register</span>
                  </Button>
                  <Button
                    type="default"
                    onClick={() => navigate('/')}
                    className="responsive-button"
                    style={{
                      width: window.innerWidth <= 768 ? '100%' : 216,
                      height: 43,
                      background: '#B1B1B1',
                      borderRadius: 69,
                    }}
                  >
                    <span style={mStyle}>Back</span>
                  </Button>
                </Flex>
              </Form.Item>
            </Form>
            
          </Flex>


        </Flex>



    </Content>
  );
};

export default Register;
